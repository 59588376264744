@font-face {
    font-family: Roboto;
    font-weight: 300;
    src: url(/assets/Roboto-Light.ttf);
}

@font-face {
    font-family: Roboto;
    font-weight: 700;
    src: url(/assets/Roboto-Regular.ttf);
}

html,
body {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    margin: 0
}

.legal {
    margin-top: auto;
    text-align: right;
    padding: 10px;
}

.content {
    text-align: center;
    max-width: 375px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 30px;
    line-height: 1.5;
}

.content__left {
    text-align: left;
}

h1 {
    font-weight: normal;
    margin-bottom: 10px;
}

.subtitle {
    margin-bottom: 50px;
}

.top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.double-button {
    display: flex;
    line-height: 30px;
    position: relative;
}

.double-button__content {
    position: absolute;
    border: 1px solid black;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    background: white;
    z-index: 1;
}

.double-button__description {
    margin: 0 0 0 15px;
    padding: 0 5px 0 20px;
    border: 1px solid black;
    border-radius: 0 10px 10px 0;
}

.double-button--right .double-button__content {
    right: 0;
}

.double-button--right .double-button__description {
    margin: 0 15px 0 0;
    padding: 0 20px 0 5px;
    border-radius: 10px 0 0 10px;
}

#next-note {
    font-size: 100px;
}

.timer-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

#timer, #restart-button {
    margin: 10px;
}

#restart-button, #retry-button {
    font-family: 'Roboto', sans-serif;
    background: none;
    padding: 10px 15px;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05) !important;
    letter-spacing: 1px;
    cursor: pointer;
    outline: none;
    display: none;
}

#retry-button {
    margin: auto;
}

#restart-button:hover {
    background: #FAFAFA;
}

#info {
    margin: 50px 0;
}

.tutorial {
    margin-top: 50px;
    text-align: left;
}

h4 {
    margin-bottom: 6px;
}

p {
    margin-top: 0;
}

.call-to-action {
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 2;
}

.call-to-action__link {
    display: inline-block;
    padding: 15px 20px;
    border-radius: 0 0 10px 10px;
    color: white;
    background: #A76B76;
    font-size: 1.1rem;
    text-align: left;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px
}

h2, h3 {
    margin-bottom: 7px;
}

.newsletter__container {
    margin-top: 30px;
}

.newsletter {
    margin: -10px -20px;
    padding: 10px 20px;
    background: aliceblue;
    position: relative;
    border-radius: 15px;
}

/*
  Animate.css
 */
.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.animate__slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

@-webkit-keyframes shakeX {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes shakeX {
    from,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

.animate__shakeX {
    -webkit-animation-name: shakeX;
    animation-name: shakeX;
}
